<template>
  <blog-layout
    v-if="isSuccess"
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <template #content>
      <blog-list
        :results="list"
        @show-more="showMore"
      />
    </template>

    <q-no-ssr>
      <viewed-products-section />
    </q-no-ssr>
  </blog-layout>

  <the-not-found v-else />
</template>

<script lang="ts" setup>
import TheNotFound from 'src/components/TheNotFound.vue'
import ViewedProductsSection from 'src/components/sections/ViewedProductsSection.vue'
import BlogLayout from 'src/layouts/BlogLayout.vue'
import BlogList from 'src/components/blog/BlogList.vue'
import { usePrefetchStore } from 'src/stores/prefetch'
import { useApiBlogGetBlogQuery, useBlogBreadcrumbs, useBlogResults, useSeoMeta, useI18n } from 'src/composables'
import { queryKeys } from 'src/api'
import { useRouteQuery } from 'src/composables/utils/route'
import { useStateStore } from 'src/stores/state'
import { computed } from 'vue'

const { t } = useI18n()

const routeQuery = useRouteQuery()

const { data, isSuccess } = useApiBlogGetBlogQuery(routeQuery)

const { list, showMore } = useBlogResults(() => data.value?.results)

const breadcrumbs = useBlogBreadcrumbs()

const title = computed(() => t('translate.news'))

useSeoMeta(() => ({
  title: title.value
}))
</script>

<script lang="ts">
export default {
  async preFetch({ store, ssrContext, currentRoute }) {
    const { locale } = useStateStore(store)
    const { api, queryClient } = usePrefetchStore(store)
    const params = currentRoute.query

    try {
      await Promise.all([
        queryClient.prefetchQuery({
          queryKey: queryKeys.blog.getGroups(locale).queryKey,
          queryFn: () => api.blog.getGroups()
        }),
        queryClient.fetchQuery({
          queryKey: queryKeys.blog.getBlog(params, locale).queryKey,
          queryFn: () => api.blog.getBlog(params)
        })
      ])
    } catch (error) {
      ssrContext?.res.status(404)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
